<template>
  <div>
    <base-content-management title="Information" contentType="info" :enableAdd="true" :enableDelete="true" :useCover="true"></base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from './BaseContentManagement.vue'

export default {
  title () {
    return `Information Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
